@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Message
// ==========================================================================

body.MessageBody {
  .main-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  @include mixin.media-all-pc {
    .container {
      height: 100%;
    }
  }
}

.Message {
  @media screen {
    padding-top: 5.8rem;
    height: 100vh;
  }

  @include mixin.media-screen-sp {
    padding-top: 4.5rem;

    &.-hide-footer {
      z-index: 1001;
    }
  }

  @at-root {
    .Message__container {
      overflow-y: auto;
      height: 100%;
      flex-grow: 2;
      margin-right: auto;
      margin-left: auto;

      @include mixin.media-all-pc {
        padding-bottom: 6rem;
      }

      @include mixin.media-screen-sp {
        padding-bottom: 8rem;
      }

      .MessageList {
        .is-editable {
          cursor: pointer;

          &:hover {
            background-color: #fcf7f7;
          }
        }

        @include mixin.media-all-pc {
          padding: 0 8.5rem;
        }

        @include mixin.media-all-tb {
          padding: 0 2rem;
        }

        @include mixin.media-screen-sp {
          padding: 0.3rem;
        }
      }

      .MessageList__header {
        margin-top: 2.4rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 83rem;
        position: relative;
        padding-left: 2.2rem;

        @include mixin.media-all-sp {
          margin-top: 5px;
          margin-bottom: 5px;
          min-width: auto;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 1.3rem;
          &.-sp-hide-search {
            .Search__filter {
              display: none;
            }
          }
        }
      }

      .MessageList__header-info {
        width: 25rem;
        @include mixin.media-all-sp {
          width: 35rem;
        }

        .MessageList__title {
          font-size: 2rem;
          margin-right: 10px;

          @include mixin.media-all-sp {
            display: none;
          }
        }

        .MessageList__item-number {
          width: 2.8rem;
          height: 2.2rem;
          border-radius: 1.1rem;
          background-color: #f54949;
          color: #fff;
          text-align: center;
          font-size: 1.9rem;
          font-weight: 200;

          &.-threeDigits {
            width: 3.8rem;
            padding-top: 0.2rem;
            padding-right: 0.1rem;
            font-size: 1.6rem;
            letter-spacing: 0.01em;
            font-weight: normal;
          }

          &.-fourDigits {
            width: 3.8rem;
            padding-top: 0.4rem;
            font-size: 1.2rem;
            letter-spacing: 0.01em;
            font-weight: normal;
          }

          @include mixin.media-all-sp {
            display: none;
          }
        }

        .MessageList__sp-search-btn {
          display: none;
          @include mixin.media-all-sp {
            display: flex;
            position: absolute;
            right: 1.2rem;
            top: 0.3rem;
            background-color: white;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            align-items: center;
            justify-content: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            img {
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }

      .MessageList__flex {
        display: flex;
        align-items: center;
      }

      .Tab {
        width: initial;
        border-bottom: none;
        position: static;
        box-shadow: none;

        @include mixin.media-all-sp {
          width: 0;
        }
      }

      .Tab__list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        position: relative;
        top: 0;
        width: 100%;
        margin-bottom: 0.6rem;
        padding: 0;
        border-bottom: none;
        background-color: initial;

        @include mixin.media-all-sp {
          margin-left: 0;
          width: 0;
        }

        li {
          width: 7em;
          height: 3rem;
          padding: none;
          border-bottom: 2px solid mixin.$color-lightGray;
          color: #999999;
          font-size: 1.2rem;
          font-weight: 700;

          @include mixin.media-all-sp {
            width: 7rem;
            height: 2.8rem;
          }

          & + li {
            margin-left: 2.8rem;

            @include mixin.media-all-sp {
              margin-left: 0.1rem;
            }

            @include mixin.media-all-tb {
              margin-left: 0.2rem;
            }
          }

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-top: 0.8rem;
            padding-bottom: 0.2rem;
          }

          &.-current {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.2rem;
            border-bottom: 2px solid #f40800;
            color: #f40800;
          }

          .Tab__name {
            display: block;
            font-weight: 700;
          }
        }
      }

      .Search__filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 1.8rem;

        @include mixin.media-all-sp {
          position: relative;
          right: inherit;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .Search__filter-search {
          input {
            @include mixin.media-all-tb {
              width: 28rem;
            }

            @include mixin.media-all-sp {
              font-size: 1.6rem;
            }

            width: 33.3rem;
            height: 2.6rem;
            padding-left: 2.5em;
            border: none;
            border-radius: 1.4rem;
            background-image: url('../../../../public/assets/img/common/icon/loupe.svg');
            background-repeat: no-repeat;
            background-size: 1rem auto;
            background-position: 0.8rem center;
            background-color: #f5f5f5;
            font-size: 1.2rem;
          }

          ::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #b3b3b3;
          }
          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b3b3b3;
          }
          ::placeholder {
            /* Others */
            color: #b3b3b3;
          }

          .Search__filter-search-history {
            position: absolute;
            top: 2.6rem;
            left: 0;
            width: 32.3rem;
            padding-right: 1.4em;
            padding-left: 1.4em;
            border-radius: 0 0 7px 7px;
            background-color: #222;
            z-index: 300;

            li {
              display: block;
              margin-top: 0.2rem;
              margin-bottom: 0.2rem;
              padding-top: 0.6rem;
              padding-bottom: 0.6rem;
              color: #fff;
              font-size: 1.2rem;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }

              &:first-child {
                margin-top: 0.8rem;
              }

              &:last-child {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }

      .Pin__icon {
        position: absolute;
        cursor: pointer;
        width: 3.5rem;
        top: 1rem;
        right: 0.1rem;

        @include mixin.media-screen-sp {
          width: 3.2rem;
          top: 1rem;
          right: 1rem;
        }
      }
    }

    .Message__reservation {
      @include mixin.media-all-pc {
        font-size: 1.4rem;
      }

      @include mixin.media-screen-sp {
        font-size: mixin.vw-value(18);
      }

      display: inline-block;
      padding: 0 6px;
      margin-top: 1rem;
      margin-left: 1rem;
      border: 1px solid mixin.$color-red;
      border-radius: 1rem;
      color: mixin.$color-red;
      font-weight: 400;
    }

    .Message__item {
      @include mixin.media-all-pc {
        padding-top: 1.2rem;
        padding-right: 1.8rem;
        padding-left: 1.8rem;
        padding-bottom: 2.3rem;
        font-size: 1.3rem;
      }

      @include mixin.media-screen-sp {
        padding-top: 1.2rem;
        padding-right: mixin.vw-value(19);
        padding-left: mixin.vw-value(19);
        padding-bottom: 1.5rem;
        font-size: 1.3rem;
      }

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #d5d5d5;

      &.-pinned {
        background: #fcf2f2;
      }

      &.-focused {
        background-color: #f9df4f66;
      }

      .Message__item-left {
        display: flex;
        flex-direction: column;

        .Message__item-icon {
          padding-bottom: 0.5rem;

          @include mixin.media-all-pc {
            width: 3.6rem;
          }

          @include mixin.media-screen-sp {
            width: mixin.vw-value(60);
          }

          @media print {
            width: 2.4rem;
          }

          flex-shrink: 0;
        }

        .Message__item-number {
          color: #888;
          text-align: center;
        }
      }

      .Message__item-main {
        @include mixin.media-all-pc {
          width: 100%;
          margin-left: 1.3rem;
        }

        @include mixin.media-screen-sp {
          width: 90%;
          margin-left: mixin.vw-value(19);
        }

        .Message__item-person {
          @include mixin.media-all-pc {
            font-size: 1.4rem;
          }

          @include mixin.media-screen-sp {
            max-width: 70%;
          }

          max-width: 50%;
          color: #3d3d3d;
          font-size: 1.4rem;
          font-weight: 700;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        .Message__item-date {
          @include mixin.media-all-pc {
          }

          margin-bottom: 0.5rem;
          color: #888;
          font-weight: 200;
        }

        .Message__item-message {
          @include mixin.media-all-pc {
            font-size: 1.3rem;
          }

          @include mixin.media-screen-sp {
            font-size: mixin.vw-value(24);
          }

          width: 100%;
          margin-top: 1.2rem;
          color: #3d3d3d;
          line-height: 1.6;
          word-wrap: break-word;
          overflow-wrap: break-word;
          white-space: pre-line;

          a {
            display: inline;
            color: #0000ee;
          }

          strong {
            font-weight: bold;
          }

          blockquote {
            border-left: 4px solid #ccc;
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 10px;
          }

          span [class*='ql-size-large'] {
            font-size: 2rem;
          }

          ol {
            counter-reset: item;
            margin-left: 0;
            padding-left: 1rem;
          }

          ol li {
            list-style: none;
            counter-increment: item;
            margin-bottom: -1.5rem;
          }

          ol li:last-child {
            margin-bottom: 0;
          }

          ol li:before {
            content: counter(item) '.';
            display: inline-block;
            margin-right: 0.5rem;
          }

          ul {
            list-style: none;
            margin-left: 0;
            padding-left: 2rem;
          }

          ul li {
            position: relative;
            margin-bottom: -1.5rem;
          }

          ul li:last-child {
            margin-bottom: 0;
          }

          ul li:before {
            content: '・';
            position: absolute;
            left: -1.5rem;
          }
        }

        .Message__item-image {
          @include mixin.media-all-pc {
            margin-top: 1.5rem;
          }

          @include mixin.media-screen-sp {
            margin-top: 1rem;
          }

          display: inline-block;
          position: relative;
          margin-left: 0.6rem;

          img {
            @include mixin.media-screen-sp {
              max-width: 80vw;
            }

            width: auto;
          }
        }
      }

      &.-info {
        @include mixin.media-screen-sp {
          padding-right: mixin.vw-value(30);
          padding-left: mixin.vw-value(30);
        }

        .Message__item-wrap {
          @include mixin.media-all-pc {
            padding-top: 2rem;
            padding-right: 2rem;
            padding-left: 2rem;
            padding-bottom: 1.8rem;
            border: 2px solid #ff8888;
          }

          @include mixin.media-screen-sp {
            padding-top: 1rem;
            padding-right: 1.2rem;
            padding-left: 1.2rem;
            padding-bottom: 1rem;
            border: 1px solid #ff8888;
          }

          width: 100%;
          border-radius: 0.6rem;

          .Message__item-title {
            @include mixin.media-all-pc {
              font-size: 1.4rem;
            }
            @include mixin.media-screen-sp {
              font-size: mixin.vw-value(24);
            }

            margin-bottom: 0.8em;
            color: #3d3d3d;
            text-align: center;
            font-weight: 700;
            word-wrap: break-word;
            overflow-wrap: break-word;
          }

          .Message__item-message {
            @include mixin.media-all-pc {
              font-size: 1.3rem;
            }
            @include mixin.media-screen-sp {
              font-size: mixin.vw-value(21);
            }

            color: #3d3d3d;
            line-height: 1.4;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: pre-line;
            margin-bottom: 2.4rem;

            & + .Message__item-message {
              @include mixin.media-all-pc {
                margin-top: 2em;
              }
              @include mixin.media-screen-sp {
                margin-top: 1.5em;
              }
            }

            a {
              color: #0000ee;
            }
          }
        }

        .Message__item-wrap :last-child {
          margin-bottom: 0;
        }
      }
    }

    .Message__item-image-menu {
      @include mixin.media-all-pc {
        width: 3.6rem;
        height: 3.6rem;
        background-size: 2.6rem;
      }
      @include mixin.media-screen-sp {
        width: 2.8rem;
        height: 2.8rem;
        background-size: 2.8rem 2.8rem;
      }

      position: absolute;
      z-index: 200;
      background-image: url('../../../../public/assets/img/common/icon/setting_menu.svg');
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &.-message {
        @include mixin.media-all-pc {
          top: 1rem;
          right: 4.5rem;
        }
        @include mixin.media-screen-sp {
          top: 1.3rem;
          right: 4.8rem;
        }
      }

      &.-file {
        @include mixin.media-all-pc {
          bottom: 0.7rem;
          right: 0.9rem;
        }
        @include mixin.media-screen-sp {
          bottom: 0.6rem;
          right: 0.8rem;
        }
      }
    }

    .Message__item-image-balloon {
      @include mixin.media-all-pc {
        width: 10em;
      }

      @include mixin.media-screen-sp {
        width: 9em;
      }

      display: none;
      position: absolute;
      background-color: #000;
      color: #fff;
      border-radius: 0.5rem;
      z-index: 100;

      &.-message {
        @include mixin.media-all-pc {
          top: 5.7rem;
          right: 2.7rem;
        }
        @include mixin.media-screen-sp {
          top: 6.4rem;
          right: 0.9rem;
        }
      }

      &.-file {
        @include mixin.media-all-pc {
          bottom: -3.8rem;
          right: -1.9rem;
        }
        @include mixin.media-screen-sp {
          bottom: -4.4rem;
          right: 0.3rem;
        }
      }

      &.-show {
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        top: -2.2rem;
        left: 8.2rem;
        border-top: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid #000;
      }

      .-delete {
        display: block;
        padding-top: 1.2rem;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
        padding-bottom: 1.1rem;
        font-size: 1.3rem;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: 1rem;
          right: 1.3rem;
          width: 1.5rem;
          height: 1.7rem;
          background-image: url('../../../../public/assets/img/common/icon/trash.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    .Message__item-image-name {
      max-width: 20rem;
    }

    .Message__item-image-deleted {
      @include mixin.media-all-pc {
        margin-top: 1.2rem;
      }

      @include mixin.media-screen-sp {
        margin-top: 1.2rem;
      }

      .Message__item-image-deleted-label {
        @include mixin.media-all-pc {
          width: 20.2rem;
          border-radius: 0.6rem;
          padding-top: 1rem;
          padding-left: 4.4rem;
          padding-bottom: 1rem;
          font-size: 1rem;
        }

        @include mixin.media-screen-sp {
          width: mixin.vw-value(400);
          border-radius: 0.6rem;
          padding-top: mixin.vw-value(18);
          padding-left: mixin.vw-value(88);
          padding-bottom: mixin.vw-value(18);
          font-size: mixin.vw-value(20);
        }

        display: flex;
        align-items: center;
        position: relative;
        background-color: #e7e7e7;
        color: #a3a3a3;

        &::before {
          @include mixin.media-all-pc {
            left: 2rem;
            width: 1.6rem;
            height: 1.6rem;
          }

          @include mixin.media-screen-sp {
            left: mixin.vw-value(38);
            width: mixin.vw-value(32);
            height: mixin.vw-value(32);
          }

          position: absolute;
          content: '';
          background-image: url('../../../../public/assets/img/messages/icon/x_01.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .Message__item-image-deleted-date {
        margin-top: 0.8rem;
        color: #888;
        font-size: 1rem;
      }
    }

    .Message__add-btn {
      @include mixin.media-all-pc {
        bottom: 5.5rem;
        right: 4.3rem;
        width: 7rem;
        height: 7rem;
        background-size: 7.5rem 7.5rem;
      }

      @include mixin.media-screen-sp {
        bottom: 5.3rem;
        right: 0.8rem;
        width: 7rem;
        height: 7rem;
        background-size: 7.5rem 7.5rem;
      }

      position: fixed;
      background-image: url('../../../../public/assets/img/common/icon/message-add.svg');
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 210;
    }

    .Message__reflex-container {
      @media print {
        height: auto;
        overflow: visible;
      }

      .Message__reflex-element {
        @media print {
          height: auto;
          overflow: visible;
        }

        &.-list {
          min-height: 6rem;
        }

        &.-form {
          overflow: visible;
          background-color: #eee;
          display: flex;
          flex-direction: column;
        }
      }

      .Message__reflex-splitter {
        border-color: transparent;
        background-color: transparent;

        @include mixin.media-all-sp {
          display: none;
        }
      }

      .Message__reflex-handle {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;

        .bar {
          width: 2rem;
          height: 1px;
          background-color: #888;
          + .bar {
            margin-top: 0.3rem;
          }
        }

        @include mixin.media-all-sp {
          display: none;
        }
      }
    }

    .Message__input-form {
      flex-grow: 1;
      display: flex;
      font-size: 1.4rem;
      width: 80%;
      height: 100%;
      margin: 0 auto 1.2rem;

      @media print {
        display: none;
      }

      @include mixin.media-screen-sp {
        width: 97%;
        flex-direction: column;
        margin-top: 0.4rem;
        margin-bottom: 0.5rem;
        position: relative;
      }

      .Message__input-form-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 100%;
        margin-left: 1rem;
        position: relative;

        @include mixin.media-screen-sp {
          flex-direction: row;
          align-items: center;
        }

        button {
          width: 6rem;
        }

        .draft-btn {
          position: absolute;
          bottom: 0;
          right: 130%;

          @include mixin.media-all-sp {
            right: 28%;
          }
        }
      }

      .Message__input-cancel {
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }
}
